


import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TNotification } from '@/_modules/promo/types/notification.type';
import PromoNotification from '@/_modules/promo/components/promo-notification/promo-notification.vue';

@Component({
  components: {
    PromoNotification,
  },
})
export default class SideBarRightTabNotifications extends Vue {

  @Getter('notificationsStore/notifications') notifications: TNotification[];

}
