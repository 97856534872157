var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"person"},[(_vm.personType === 'default')?[_c('ew-avatar',{attrs:{"contact":_vm.contact,"width":_vm.avatarSize(),"disable-unread-count":_vm.disableUnreadCount,"unread-count":+_vm.prepareBadgeNotificationNumber((_vm.contact && _vm.contact.unread_messages) || 0)}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"primary-text person-name"},[_vm._v(_vm._s(_vm.fullName))]),_c('div',{staticClass:"secondary-text person-workplace"},[_vm._v(_vm._s(_vm.companyPosition))]),(_vm.isExponent)?_c('div',{staticClass:"secondary-text person-workplace"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('tooltips.clickableExhibitorTypeBadge'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('tooltips.clickableExhibitorTypeBadge'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}],staticClass:"clickable-text",on:{"click":function($event){$event.stopPropagation();return _vm.onClickableCompanyNameClick($event)}}},[_vm._v(_vm._s(_vm.companyName))])]):_c('div',{staticClass:"secondary-text person-workplace"},[_vm._v(" "+_vm._s(_vm.companyName)+" ")]),_c('div',{staticClass:"secondary-text person-location"},[_vm._v(_vm._s(_vm.contactGeoInfo))]),(_vm.isExponent)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('tooltips.clickableExhibitorTypeBadge'),
          classes: ['vue-tooltip-dark'],
          placement: 'bottom',
          delay: 0,
        }),expression:"{\n          content: $t('tooltips.clickableExhibitorTypeBadge'),\n          classes: ['vue-tooltip-dark'],\n          placement: 'bottom',\n          delay: 0,\n        }"}],staticClass:"person-type",class:("person-type-" + _vm.personStatus),on:{"click":function($event){$event.stopPropagation();return _vm.onClickableCompanyNameClick($event)}}},[_vm._v(" "+_vm._s(_vm.personStatusTranslated)+" ")]):_c('div',{staticClass:"person-type",class:("person-type-" + _vm.personStatus)},[_vm._v(" "+_vm._s(_vm.personStatusTranslated)+" ")])]),(!_vm.hideActions)?_c('div',{staticClass:"person-actions"},[_c('div',{staticClass:"action action-togglefav"},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite($event)}}},[(_vm.contact.is_favorite)?_c('iconFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('button.rm_favorites'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('button.rm_favorites'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}]}):_c('iconUnFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('button.favorites'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('button.favorites'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}]})],1)]),_c('div',{staticClass:"action action-share"},[_c('span',[_c('iconShare',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('button.share'),
                classes: ['vue-tooltip-dark'],
                placement: 'bottom',
                delay: 0,
              }),expression:"{\n                content: $t('button.share'),\n                classes: ['vue-tooltip-dark'],\n                placement: 'bottom',\n                delay: 0,\n              }"}],nativeOn:{"click":function($event){$event.stopPropagation();return _vm.sharePerson($event)}}})],1)])]):_vm._e()]:_vm._e(),(_vm.personType === 'small')?[_c('ew-avatar',{attrs:{"contact":_vm.contact,"width":_vm.avatarSize(),"disable-online-badge":_vm.disableOnlineBadge}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"primary-text person-name clickable-text"},[_vm._v(_vm._s(_vm.fullName))]),_c('div',{staticClass:"secondary-text person-workplace"},[_vm._v(_vm._s(_vm.companyPosition))]),(_vm.isExponent)?_c('div',{staticClass:"secondary-text person-workplace"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('tooltips.clickableExhibitorTypeBadge'),
            classes: ['vue-tooltip-dark'],
            placement: 'bottom',
            delay: 0,
          }),expression:"{\n            content: $t('tooltips.clickableExhibitorTypeBadge'),\n            classes: ['vue-tooltip-dark'],\n            placement: 'bottom',\n            delay: 0,\n          }"}],staticClass:"clickable-text",on:{"click":function($event){$event.stopPropagation();return _vm.onClickableCompanyNameClick($event)}}},[_vm._v(_vm._s(_vm.companyName))])]):_c('div',{staticClass:"secondary-text person-workplace"},[_vm._v(" "+_vm._s(_vm.companyName)+" ")]),_c('div',{staticClass:"secondary-text person-location"},[_vm._v(_vm._s(_vm.contactGeoInfo))]),(_vm.isExponent)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('tooltips.clickableExhibitorTypeBadge'),
          classes: ['vue-tooltip-dark'],
          placement: 'bottom',
          delay: 0,
        }),expression:"{\n          content: $t('tooltips.clickableExhibitorTypeBadge'),\n          classes: ['vue-tooltip-dark'],\n          placement: 'bottom',\n          delay: 0,\n        }"}],staticClass:"person-type",class:("person-type-" + _vm.personStatus),on:{"click":function($event){$event.stopPropagation();return _vm.onClickableCompanyNameClick($event)}}},[_vm._v(" "+_vm._s(_vm.personStatusTranslated)+" ")]):_c('div',{staticClass:"person-type",class:("person-type-" + _vm.personStatus)},[_vm._v(" "+_vm._s(_vm.personStatusTranslated)+" ")])])]:_vm._e(),(_vm.personType === 'calendar')?[_c('ew-avatar',{attrs:{"contact":_vm.contact,"width":_vm.avatarSize(),"disable-online-badge":_vm.disableOnlineBadge}}),_c('div',{staticClass:"content",class:(_vm.personType + "-person-content")},[_c('div',{staticClass:"primary-text person-name clickable-text"},[_vm._v(_vm._s(_vm.fullName))]),(!_vm.disableOnlineIndicator)?_c('online-indicator',{staticClass:"indicator",attrs:{"indicator-type":_vm.indicatorType}}):_vm._e()],1)]:_vm._e(),(_vm.personType === 'speaker')?[_c('ew-avatar',{attrs:{"contact":_vm.contact,"width":_vm.avatarSize(),"disable-online-badge":_vm.disableOnlineBadge}}),_c('div',{staticClass:"content",class:(_vm.personType + "-person-content")},[_c('div',{staticClass:"primary-text person-name "},[_vm._v(_vm._s(_vm.fullName))]),_c('div',{staticClass:"secondary-text person-workplace"},[_vm._v(_vm._s(_vm.companyName))])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }