


import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class OnlineIndicator extends Vue {

  @Prop({ type: Number, default: 0 })
  public contactId: number;

  @Prop({
    type: String,
    default: 'offline',
    validator: (value: string) => {
      return ['online', 'offline', 'expired'].includes(value);
    }
  })
  public readonly indicatorType: string;

}

