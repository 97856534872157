<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.06846 15.2681H12.5988C12.8541 15.2681 13.063 15.0592 13.063 14.8039C13.063 14.6786 13.0166 14.5625 12.9284 14.4743C12.8402 14.3861 12.7242 14.3397 12.5988 14.3397H3.06846C2.81315 14.3397 2.60425 14.5486 2.60425 14.8039C2.60425 15.0592 2.8085 15.2681 3.06846 15.2681Z"
      fill="black"/>
    <path
      d="M8.29554 16.9997C8.29554 16.7444 8.08665 16.5355 7.83133 16.5355H3.06846C2.81315 16.5355 2.60425 16.7444 2.60425 16.9997C2.60425 17.255 2.81315 17.4639 3.06846 17.4639H7.83597C8.08665 17.4639 8.29554 17.255 8.29554 16.9997Z"
      fill="black"/>
    <path
      d="M1.48084 6.42476H4.0201L5.10173 8.29091C5.23635 8.52766 5.48238 8.66692 5.75627 8.66692C6.03016 8.66692 6.27155 8.52766 6.41082 8.29091L7.49244 6.42476H10.0271C10.8348 6.42476 11.4893 5.77021 11.4893 4.96248V1.46228C11.4893 0.654545 10.8302 0 10.0271 0H1.48548C0.747375 0 0.125325 0.547776 0.0324812 1.2766C0.0231969 1.31373 0.0185547 1.34623 0.0185547 1.38337V4.96248C0.0185547 5.77021 0.6731 6.42476 1.48084 6.42476ZM1.48084 0.928433H10.0224C10.3195 0.928433 10.5563 1.16983 10.5563 1.46228V4.95783C10.5563 5.25493 10.3149 5.49168 10.0224 5.49168H7.42745C7.135 5.49168 6.86111 5.64952 6.71256 5.90484L5.75163 7.56673L4.7907 5.90948C4.64215 5.65416 4.36826 5.49633 4.07581 5.49633H1.48084C1.18374 5.49633 0.946988 5.25493 0.946988 4.96248V1.46228C0.946988 1.16983 1.18838 0.928433 1.48084 0.928433Z"
      fill="black"/>
    <path
      d="M23.3687 7.16289C22.9602 6.75438 22.4217 6.53156 21.8507 6.53156H12.1346C10.9509 6.53156 9.9853 7.49713 9.9853 8.68088V10.4681H2.36286C1.05841 10.4728 0 11.5358 0 12.8356V18.4851C0 19.7664 1.02592 20.8155 2.30251 20.848L1.87544 23.2109C1.82437 23.4987 1.95899 23.7818 2.21431 23.9211C2.3118 23.9722 2.42321 24 2.53462 24C2.70638 24 2.86886 23.935 2.9942 23.8143L6.0766 20.8898C6.10445 20.8619 6.14159 20.848 6.17872 20.848H13.2952C14.5996 20.848 15.658 19.7896 15.658 18.4851V15.7834H18.4526C18.4758 15.7834 18.4944 15.7927 18.5083 15.8066L21.2426 18.4016C21.4468 18.5965 21.7485 18.6337 21.9946 18.5037C22.2406 18.3691 22.3752 18.0952 22.3242 17.8213L21.9574 15.7834C22.4959 15.7555 23.0019 15.5281 23.3826 15.1335C23.7772 14.7296 23.9954 14.1958 23.9954 13.6341V8.68552C24 8.10989 23.7725 7.5714 23.3687 7.16289ZM21.1544 15.1056C21.0197 15.2635 20.964 15.4724 21.0012 15.6766L21.2658 17.1389L19.1536 15.1335C18.9632 14.9524 18.7172 14.855 18.4573 14.855H15.6627V12.8356C15.6627 12.2043 15.4166 11.6101 14.971 11.1644C14.5253 10.7188 13.9311 10.4728 13.2998 10.4728H10.9137V8.68552C10.9137 8.01241 11.4615 7.46463 12.1346 7.46463H21.846C22.5191 7.46463 23.0669 8.01241 23.0669 8.68552V13.6341C23.0669 14.3072 22.5191 14.855 21.846 14.855H21.6882C21.4793 14.8596 21.2843 14.9478 21.1544 15.1056ZM3.2588 20.7784C3.29594 20.5648 3.24023 20.3466 3.10097 20.1842C2.9617 20.017 2.75745 19.9242 2.54391 19.9242H2.36286C1.57369 19.9242 0.928433 19.2789 0.928433 18.4898V12.8356C0.928433 12.0464 1.57369 11.4012 2.36286 11.4012H13.2998C14.089 11.4012 14.7342 12.0464 14.7342 12.8356V18.4851C14.7342 19.2743 14.089 19.9196 13.2998 19.9196H6.18337C5.90484 19.9196 5.64023 20.0263 5.44062 20.2167L2.93385 22.5981L3.2588 20.7784Z"
      fill="black"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconTextChats = Vue.extend({
  name: 'icon-text-chats',
});
export default IconTextChats;
</script>
