


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TScheduleDay, TScheduleYear } from '@/_modules/ew-calendar/types/ew-calendar.type';
import { Moment } from 'moment';
import IconChevronLeft from '@/_modules/icons/components/ew-calendar/chevron-left.vue';
import IconChevronRight from '@/_modules/icons/components/ew-calendar/chevron-right.vue';

@Component({
  components: {
    IconChevronLeft,
    IconChevronRight,
  },
})
export default class EwCalendar extends Vue {
  @Getter('_eventStore/event') event: TEvent;
  @Getter('calendarStore/getMarkedDates') markedDates: string[];
  @Getter('_eventStore/selectedTimezone') selectedTimezone: string;

  public year: TScheduleYear = { byWeeks: {}, byMonths: {} };
  public targetDate: Date = null;
  public monthNumber: number = 0;
  public fullDate: Date = null;
  public currentYear: number = null;
  public viewedWeekShift: number = 0;

  @Prop({ type: Boolean, default: false })
  public readonly byMonth: false;

  // TODO: wtf. this must be a number, 0-6. Rewrite everything )
  @Prop({ type: Boolean, default: true })
  public readonly defaultDayOfWeek: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly showDate: true;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get renderWeek(): TScheduleDay[] {
    const DAY_MS = 86400000;
    const targetWeekStartDayIndex: number = this.defaultDayOfWeek ? 0 : 1;

    let viewStart: Date = new Date((new Date()).getTime() + 7 * this.viewedWeekShift * DAY_MS);
    while (viewStart.getDay() !== targetWeekStartDayIndex) {
      viewStart = new Date(viewStart.getTime() - DAY_MS);
      viewStart.setHours(0, 0, 0, 0);
    }

    const res: TScheduleDay[] = [];
    let iteratedDate = new Date(viewStart);
    for (let i = 0; i < 7; i++) {
      res.push(this.getScheduleDayFromDate(iteratedDate));
      iteratedDate = new Date(iteratedDate.getTime() + DAY_MS);
    }

    return res || [];
  }

  public get renderMonth(): TScheduleDay[] {
    return (this.year && this.year.byMonths && this.year.byMonths[this.monthNumber]) || [];
  }

  public get formattedMonth(): string {
    return this.fullDate && this.$moment(this.fullDate).format('MMMM, YYYY');
  }

  public get dayNames(): string[] {
    if (this.defaultDayOfWeek) {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }
    return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  }

  @Watch('markedDates', { immediate: true })
  public onMarkedDatesChanged(): void {
    this.markDaysInCurrentCalendarView();
    this.chooseFirstActualMarkedDate();
  }

  @Watch('monthNumber')
  public onMonthNumberChange(): void {
    this.markDaysInCurrentCalendarView();
  }

  public created(): void {
    this.fullDate = new Date();
    this.monthNumber = this.fullDate.getMonth();
    this.currentYear = this.fullDate.getFullYear();

    if (this.byMonth) {
      for (let i = 0; i < 12; i++) {
        this.fillYearByMonth(i, this.currentYear);
      }
    }

    this.chooseToday();
  }

  public getScheduleDayFromDate(date: Date): TScheduleDay {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);

    const day: Moment = this.$moment(d);
    return {
      dayNumber: d.getDate().toFixed(0).padStart(2, '0'),
      dayName: day.format('dddd'),
      dayOfWeekNumber: d.getDay(),
      monthName: day.format('MMMM'),
      monthNumber: d.getMonth(),
      isMarked: this.isDateMarked(d),
      weekNumber: day.clone().startOf('week'),
      isEventDay: this.isEventDate(d),
      fullDate: new Date(d),
    };
  }

  public markDaysInCurrentCalendarView(): void {
    if (this.byMonth && this.year.byMonths[this.monthNumber]) {
      this.year.byMonths[this.monthNumber].forEach(day => {
        day.isMarked = this.isDateMarked(day.fullDate);
      });
    }
  }

  public isDateMarked(date: Date): boolean {
    return !!(this.markedDates || []).find(markedDate => {
      const dateWithoutHours = this.changeTimeZone(markedDate).setHours(0, 0, 0, 0);
      return dateWithoutHours === date.setHours(0, 0, 0, 0);
    });
  }

  // TODO: refactor - accept only date; better naming
  public changeTimeZone(date: Date | string): Date {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return new Date(
      date.toLocaleString('en-US', {
        timeZone: this.selectedTimezone,
      }),
    );
  }

  public chooseToday(): void {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);

    this.chooseDate({
      dayName: '',
      dayNumber: '',
      dayOfWeekNumber: null,
      isEventDay: false,
      monthName: '',
      isMarked: false,
      monthNumber: null,
      weekNumber: null,
      fullDate: today
    });
  }

  public chooseFirstActualMarkedDate(): void {
    if (!this.markedDates.length) {
      this.chooseToday();
      return;
    }

    const found = this.markedDates.find(date => {
      return new Date(this.targetDate).setHours(0, 0, 0, 0) <= this.changeTimeZone(new Date(date)).setHours(0, 0, 0, 0);
    });

    if (!found) {
      this.chooseToday();
      return;
    }

    this.chooseDate({
      dayName: '',
      dayNumber: '',
      dayOfWeekNumber: null,
      isEventDay: false,
      monthName: '',
      isMarked: true,
      monthNumber: null,
      weekNumber: null,
      fullDate: new Date(this.changeTimeZone(new Date(found)).setHours(0, 0, 0, 0))
    });
  }

  public fillYearByMonth(month: number, year: number): void {

    const monthLength = this.getMonthLengthByDate(new Date(year, month, 1, 0, 0, 0, 0));
    const monthObj: TScheduleDay[] = [];

    for (let i = 1; i <= monthLength; i++) {
      const fullDate = new Date(year, month, i);
      const fullDateMoment: Moment = this.$moment(fullDate);

      monthObj[i - 1] = {
        dayNumber: i.toFixed(0).padStart(2, '0'),
        dayName: fullDateMoment.format('dd'),
        dayOfWeekNumber: fullDate.getDay(),
        monthName: fullDateMoment.format('MMMM'),
        weekNumber: fullDateMoment.week(),
        isEventDay: this.isEventDate(fullDate),
        isMarked: this.isDateMarked(fullDate),
        monthNumber: fullDate.getMonth(),
        fullDate
      };
    }

    this.year.byMonths[month] = monthObj;
  }

  public getMonthLengthByDate(date: Date): number {
    const givenMonthNumber: number = date.getMonth();
    let result = 0;
    while (date.getMonth() === givenMonthNumber) {
      result = date.getDate() + 1;
      date.setDate(result);
    }
    return result - 1;
  }

  public isEventDate(date: Date): boolean {
    if (!this.event) {
      return false;
    }

    const midnightDateStart = new Date(this.event.date_start).setHours(0, 0, 0, 0);
    return midnightDateStart <= +date && +this.event.date_end >= +date;
  }

  public rewindWeek(): void {
    this.viewedWeekShift--;
  }

  public forwardWeek(): void {
    this.viewedWeekShift++;
  }

  public rewindMonth(): void {
    if (this.fullDate.getMonth() > 0) {
      const date = this.$moment(this.fullDate).startOf('month').clone().subtract(1, 'month');
      this.monthNumber = date.month();
      this.fullDate = date.toDate();
      return;
    }

    const date = new Date(this.fullDate);
    date.setFullYear(this.fullDate.getFullYear() - 1, 11, 1);
    this.fullDate = date;
    this.monthNumber = 11;
    this.year = { byWeeks: this.year.byWeeks, byMonths: {} };

    this.currentYear = this.fullDate.getFullYear();

    for (let i = 0; i < 12; i++) {
      this.fillYearByMonth(i, this.currentYear);
    }
  }

  public forwardMonth(): void {
    if (this.fullDate.getMonth() < 11) {
      const date = this.$moment(this.fullDate).startOf('month').clone().add(1, 'month');
      this.monthNumber = date.month();
      this.fullDate = date.toDate();
      return;
    }

    const date = new Date(this.fullDate);
    date.setFullYear(this.fullDate.getFullYear() + 1, 0, 1);

    this.fullDate = date;
    this.monthNumber = 0;
    this.year = { byWeeks: this.year.byWeeks, byMonths: {} };

    this.currentYear = this.fullDate.getFullYear();

    for (let i = 0; i < 12; ++i) {
      this.fillYearByMonth(i, this.currentYear);
    }
  }

  public chooseDate(day: TScheduleDay): void {
    this.targetDate = day.fullDate;
    this.$emit('targetScheduleDay', day);
  }

  public isDayChosen(day: TScheduleDay): boolean {
    return +this.targetDate === +day.fullDate;
  }

  public isDayToday(day: TScheduleDay): boolean {
    return day.fullDate.setHours(0, 0, 0, 0) === (new Date()).setHours(0, 0, 0, 0);
  }

  public isFirstDayRounded(day: TScheduleDay): boolean {
    if (!this.event) {
      return false;
    }
    return day.fullDate.getTime() === (new Date(this.event.date_start).setHours(0, 0, 0, 0));
  }

  public isLastDayRounded(day: TScheduleDay): boolean {
    if (!this.event) {
      return false;
    }
    return day.fullDate.getTime() === (new Date(this.event.date_end).setHours(0, 0, 0, 0));
  }

  public getGridColumnStartCSSRule(day: TScheduleDay): string {
    let gridColumnNumber = this.defaultDayOfWeek ? day.dayOfWeekNumber + 1 : day.dayOfWeekNumber;
    gridColumnNumber = gridColumnNumber === 0 ? 7 : gridColumnNumber;
    return `grid-column-start: ${gridColumnNumber};`;
  }
}

