


import Component from 'vue-class-component';
import {Vue, Watch} from 'vue-property-decorator';

import { mapGetters } from 'vuex';
import { TUser } from '@/_types/user.type';
import timezone from '@/_modules/events/components/timezones.json';
import { TTimezoneInfo } from '@/_types/timezone-info.type';
import { TUserConfig } from '@/_types/user-config.type';
import _cloneDeep from 'lodash.clonedeep';
import { TranslateResult } from 'vue-i18n';

type TUserLanguageOption = {
  twoLetterCode: string;
  languageName: TranslateResult;
}

@Component({
  components: {
  },
  computed: {
    ...mapGetters('_userStore', {
      user: 'user'
    }),
  },
})
export default class SideBarRightUserSettings extends Vue {

  public timezone: TTimezoneInfo[] = timezone;
  public selectedTimezone: TTimezoneInfo = timezone[0];
  public userLanguageOptions: TUserLanguageOption[] = [
    {
      twoLetterCode: 'en',
      languageName: this.$t('languageNames.en')
    },
    {
      twoLetterCode: 'ru',
      languageName: this.$t('languageNames.ru')
    },
    {
      twoLetterCode: 'uk',
      languageName: this.$t('languageNames.uk')
    },
    {
      twoLetterCode: 'zh',
      languageName: this.$t('languageNames.zh')
    },
    {
      twoLetterCode: 'lt',
      languageName: this.$t('languageNames.lt')
    },
    {
      twoLetterCode: 'vi',
      languageName: this.$t('languageNames.vi')
    },
    {
      twoLetterCode: 'bg',
      languageName: this.$t('languageNames.bg')
    },
    {
      twoLetterCode: 'sq',
      languageName: this.$t('languageNames.sq')
    },
  ];
  public localUserConfig: TUserConfig = {
    time_region: '',
    lang: 'en',
    allow_all_sms: true,
    allow_all_email: true,
  };

  public readonly user: TUser;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isEmailConsentCheckboxDisabled(): boolean {
    return !this.user;
  }

  public get isSmsConsentCheckboxDisabled(): boolean {
    return !this.user;
  }

  @Watch('user', {immediate: true})
  private onUserChange(): void {
    this.initLocalUserConfig();
  }

  @Watch('user.config')
  private onUserConfigChange(): void {
    this.initLocalUserConfig();
  }

  private onTimezoneSelectChange(timezone: TTimezoneInfo): void {
    this.selectedTimezone = _cloneDeep(timezone);
    this.localUserConfig.time_region = _cloneDeep(timezone.utc[0]);
    this.saveUserConfig();
  }

  private onLangSelectChange(): void {
    this.saveUserConfig();
  }

  private toggleEmailConsent(): void {
    if (this.isEmailConsentCheckboxDisabled) {
      return;
    }
    this.localUserConfig.allow_all_email = !this.localUserConfig.allow_all_email;
    this.saveUserConfig();
  }

  private toggleSmsConsent(): void {
    if (this.isSmsConsentCheckboxDisabled) {
      return;
    }
    this.localUserConfig.allow_all_sms = !this.localUserConfig.allow_all_sms;
    this.saveUserConfig();
  }

  private initLocalUserConfig(): void {
    this.localUserConfig = _cloneDeep(this.user.config);
    this.initTimezone();
  }

  private initTimezone(): void {
    const foundTimezoneItem = this.timezone.find(tzItem => {
      return tzItem.utc[0] === this.localUserConfig.time_region;
    });
    if(foundTimezoneItem) {
      this.selectedTimezone = foundTimezoneItem;
      this.localUserConfig.time_region = foundTimezoneItem.utc[0];
    }
  }

  private saveUserConfig(): void {
    this.$store.dispatch('_userStore/patchUserConfig', this.localUserConfig);
  }

}
