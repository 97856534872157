


import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import _isEqual from 'lodash.isequal';
import {
  MENU_CUSTOM_TITLE_KEY_NAME,
  MENU_PROPERTIES_KEY_NAME
} from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';
import { TEvent } from '@/_types/event.type';
import eventApi, { TEventRestrictions } from '@/_modules/events/api/event/event.api';
import { TContactPermissions } from '@/_modules/contacts/store/contacts.store';
import { TEventSettings } from '@/_types/event-settings.type';
import { TContact } from '@/_types/contact.type';
import UtilsHelper from '@/_helpers/utils.helper';

import HelpCrunchService from '@/_services/help-crunch.service';

import iconHome from '@/_modules/icons/components/sidebar/icon-home.vue';
import iconInfo from '@/_modules/icons/components/sidebar/icon-info.vue';
import IconProgram from '@/_modules/icons/components/sidebar/icon-program.vue';
import iconHall from '@/_modules/icons/components/sidebar/icon-hall.vue';
import iconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';
import iconMeetings from '@/_modules/icons/components/sidebar/icon-meetings.vue';
import iconDiscussions from '@/_modules/icons/components/sidebar/icon-discussions.vue';
import iconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';
import iconResult from '@/_modules/icons/components/sidebar/icon-result.vue';
import iconTextChats from '@/_modules/icons/components/sidebar/icon-text-chats.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import IconHelp from '@/_modules/icons/components/sidebar/icon-help.vue';

export type TSideBarLeftMenuItem = {
  routeName?: string;
  customActionName?: string;
  routeQuery?: any;
  active: boolean;
  isActive?: () => boolean;
  isDisabled?: () => boolean;
  title?: string;
  iconComponentName?: string;
  sorting?: number;
  isSorted?: boolean;
}

@Component({
  components: {
    iconHome,
    iconInfo,
    IconProgram,
    iconHall,
    iconContacts,
    iconMeetings,
    iconDiscussions,
    iconNotes,
    iconResult,
    iconTextChats,
    iconNews,
    IconHelp
  },
})
export default class PromoSideBarLeft extends mixins(NotificationsMixin) {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/isLoading') isEventLoading: boolean;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;
  @Getter('_eventStore/eventRestrictions') eventRestrictions: TEventRestrictions;
  @Getter('notificationsStore/meetingsCount') meetingsCount: number;
  @Getter('notificationsStore/noticedMeetingsCount') noticedMeetingsCount: number;
  @Getter('notificationsStore/noticedMessagesCount') noticedMessagesCount: number; // TODO: unused?
  @Getter('contactsStore/contactPermissions') contactPermissions: TContactPermissions;
  @Getter('promoPageStore/contact') public readonly myself: TContact;

  @Action('_eventStore/refreshAsync') refreshEvent: () => Promise<void>;

  public menuItems: TSideBarLeftMenuItem[] = [
    {
      routeName: 'event-info',
      active: true,
      isActive: (): boolean => this.$route.name === 'event-info',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.info',
      iconComponentName: 'icon-home',
    },
    {
      routeName: 'promo-program',
      active: true,
      isActive: (): boolean => { return this.$route.meta && this.$route.meta.isProgramRoute; },
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.program',
      iconComponentName: 'icon-program',
    },
    {
      routeName: 'promo-contacts',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-contacts') === 0) || (this.$route.name === 'promo-contacts') || (this.$route.name.indexOf('promo-contact-') > -1),
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.contacts',
      iconComponentName: 'icon-contacts',
      routeQuery: {},
    },
    {
      routeName: 'promo-page-events-companies',
      active: true,
      isActive: (): boolean => (this.$route.name.indexOf('promo-page-events') === 0) && (this.$route.name !== 'promo-contacts'),
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.hall',
      iconComponentName: 'icon-hall',
    },
    {
      routeName: 'promo-live',
      active: true,
      isActive: (): boolean => this.$route.name === 'promo-live',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.live',
      iconComponentName: 'icon-info',
    },
    {
      routeName: 'text-chats',
      active: true,
      isActive: (): boolean => this.$route.name === 'text-chats' || this.$route.name === 'text-chat-expanded',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.textChats',
      iconComponentName: 'icon-text-chats',
    },
    {
      routeName: 'news',
      active: true,
      isActive: (): boolean => this.$route.name === 'news' || this.$route.name === 'news-issue',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.news',
      iconComponentName: 'icon-news',
    },
    {
      routeName: 'notes',
      active: true,
      isActive: (): boolean => this.$route.name === 'notes',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.notes',
      iconComponentName: 'icon-notes',
    },
    {
      routeName: 'promo-page-calendar',
      active: true,
      isActive: (): boolean => this.$route.name.indexOf('promo-page-calendar') > -1,
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.calendar',
      iconComponentName: 'icon-meetings',
    },
    {
      routeName: 'result',
      active: true,
      isActive: (): boolean => this.$route.name === 'result-personal' || this.$route.name === 'result-company' || this.$route.name === 'result-event',
      isDisabled: (): boolean => this.isEventLoading,
      title: 'sideBar.result',
      iconComponentName: 'icon-result',
    },
    {
      customActionName: 'help',
      active: true,
      isActive: (): boolean => false,
      isDisabled: (): boolean => this.isHelpMenuItemDisabled,
      title: 'sideBar.help',
      iconComponentName: 'icon-help',
    },
  ];

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get sortedMenuItems(): TSideBarLeftMenuItem[] {
    this.menuItems.forEach((item, index) => {
      if (this.getMenuItemSorting(item)) {
        this.menuItems[index].sorting = this.getMenuItemSorting(item);
        if (this.getMenuItemIsSorted(item) !== undefined) {
          this.menuItems[index].isSorted = this.getMenuItemIsSorted(item);
        }
      }
    });

    return this.menuItems.sort((a, b) => {
      if (a.sorting < b.sorting) {
        return -1;
      } else if (a.sorting > b.sorting) {
        return 1;
      }
      return 0;
    });
  }

  public isMenuItemHavingDividerBefore(menuItem: TSideBarLeftMenuItem): boolean {
    const index = this.activeMenuItems.findIndex(item => item === menuItem);
    if (index === 0) {
      return false;
    }
    const previousItem = this.activeMenuItems[index - 1];
    return menuItem.isSorted === false && previousItem.isSorted !== false;
  }

  public get activeMenuItems(): TSideBarLeftMenuItem[] {
    return this.sortedMenuItems.filter(x => x.active && this.isMenuItemShown(x));
  }

  public get isHelpCrunchChatReady(): boolean {
    const doc: HTMLElement = document.documentElement;
    return doc.classList.contains('root-helpcrunch-chat-ready');
  }

  public get isHelpMenuItemDisabled(): boolean {
    return !this.isEventLoading && this.isHelpCrunchChatReady;
  }

  public get layout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get menuProps(): any {
    return (this.layout && this.layout[MENU_PROPERTIES_KEY_NAME]) || null;
  }

  // TODO: remove, it is not passed from anywhere
  @Prop({ type: Boolean, default: false })
  public readonly accessCheckNecessary: boolean;

  public mounted(): void {
    this.checkEventIsEnabled();
    this.$store.dispatch('sideBarLeftStore/close');
  }

  private checkEventIsEnabled(): void {
    if (this.$route.name === 'event-info') {
      return;
    }

    if (!this.event || !this.event.personal) {
      window.setTimeout(() => {
        this.checkEventIsEnabled();
      }, 250);
      return;
    }

    if (this.isEventAccessEnabled() === false) {
      this.handleEventAccessDisabled();
    }
  }

  private async handleEventAccessDisabled(): Promise<void> {
    await this.$store.dispatch('_eventStore/setPrivateEventDialogVisibility', true);

    try {
      this.$router.push({
        name: 'event-info',
        params: { eventId: this.$route.params.eventId }
      });
    } catch {
      /* ignore */
    }
  }

  private isEventAccessEnabled(): boolean {
    return this.event.personal.is_creator || this.event.is_enabled;
  }

  private isRouteQueryEqual(menuItem: TSideBarLeftMenuItem): boolean {
    return (!this.$route.query || !menuItem.routeQuery || _isEqual(this.$route.query, menuItem.routeQuery));
  }

  private async menuItemClickHandler(event: Event, item: TSideBarLeftMenuItem): Promise<void> {
    event.preventDefault();

    if (item.customActionName) {
      this.dispatchCustomAction(item);
      return;
    }

    if (this.isAdditionToContactsNeeded()) {
      await eventApi.patchIsGoing({ eventId: this.eventId, isGoing: true });
      await this.refreshEvent();
    }

    if (this.hasRestrictions(item) && !this.hasPermissions(item)) {
      return;
    }

    const routeName: string = item.routeName;

    if (!routeName || ((routeName === this.$route.name) && this.isRouteQueryEqual(item))) {
      return;
    }

    this.$emit('promoGoto', routeName); // TODO: check and remove if possible

    // For showPromoAccessPopup // TODO: remove, unused
    if (this.accessCheckNecessary) {
      return;
    }

    try {
      this.$router.push({
        name: routeName,
        params: { eventId: this.$route.params.eventId },
        query: item.routeQuery || undefined,
      }).catch(e => e);
    } catch {
      /* ignore */
    }
  }

  public isAdditionToContactsNeeded(): boolean {
    // Event is freely accessible, the user is authenticated but not a contact at this event.
    return this.isAuthenticated && this.event && this.event.access_type === 'free';
  }

  private dispatchCustomAction(menuItem: TSideBarLeftMenuItem): void {
    if (menuItem.customActionName === 'help') {
      this.openSupportChat();
    }
  }

  private hoverHandler(event: MouseEvent): void {
    if (!event || !event.type) {
      return;
    }

    if (event.type === 'mouseenter') {
      this.$store.dispatch('sideBarLeftStore/open');
    } else if (event.type === 'mouseleave') {
      this.$store.dispatch('sideBarLeftStore/close');
    }

  }

  private isMenuItemShown(menuItem: TSideBarLeftMenuItem): boolean {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName); // AW-2349
    if (!this.menuProps || !this.menuProps[menuItemRouteNameSnakeCase]) {
      return true;
    }
    return this.menuProps[menuItemRouteNameSnakeCase].isShown;
  }

  private getMenuItemSorting(menuItem: TSideBarLeftMenuItem): number {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName); // AW-2349
    if (!this.menuProps || !this.menuProps[menuItemRouteNameSnakeCase]) {
      return null;
    }
    return this.menuProps[menuItemRouteNameSnakeCase].sorting;
  }

  private getMenuItemIsSorted(menuItem: TSideBarLeftMenuItem): boolean {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName);
    if (!this.menuProps || !this.menuProps[menuItemRouteNameSnakeCase]) {
      return null;
    }
    return this.menuProps[menuItemRouteNameSnakeCase].isSorted;
  }

  private isCalendarBadgeNotificationNeeded(item: TSideBarLeftMenuItem): boolean {
    return (item.routeName === 'promo-page-calendar') && (this.meetingsCount - this.noticedMeetingsCount > 0);
  }

  private openSupportChat(): void {
    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) {
      window.setTimeout(() => { this.openSupportChat(); }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

  public getMenuItemHref(menuItem: TSideBarLeftMenuItem): string {
    if (menuItem.customActionName) {
      return null; // N.B.: null here means that href won't be present in the DOM
    }
    return this.$router.resolve({
      name: menuItem.routeName,
      params: {
        eventId: this.$route.params.eventId
      }
    }).href;
  }

  public hasRestrictions(menuItem: TSideBarLeftMenuItem): boolean {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName);

    if (this.eventRestrictions && this.eventRestrictions.restrictions_data) {
      if (this.eventRestrictions.restrictions_data.indexOf(menuItemRouteNameSnakeCase) >= 0) {
        if (this.eventRestrictions.restrictions_data[this.eventRestrictions.restrictions_data.indexOf(menuItemRouteNameSnakeCase)] === menuItemRouteNameSnakeCase) {
          return true;
        }
      }
    }

    return false;
  }

  public hasPermissions(menuItem: TSideBarLeftMenuItem): boolean {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName);

    if (this.contactPermissions && this.contactPermissions.permissions_data) {
      if (this.contactPermissions.permissions_data.indexOf(menuItemRouteNameSnakeCase) >= 0) {
        if (this.contactPermissions.permissions_data[this.contactPermissions.permissions_data.indexOf(menuItemRouteNameSnakeCase)] === menuItemRouteNameSnakeCase) {
          return true;
        }
      }
    }

    return false;
  }

  public getMenuItemTitle(menuItem: TSideBarLeftMenuItem): string {
    const menuItemRouteNameSnakeCase = UtilsHelper.routeNamesToSettingsKeys(menuItem.routeName);
    const customTitleDict = (this.menuProps && this.menuProps[menuItemRouteNameSnakeCase] && this.menuProps[menuItemRouteNameSnakeCase][MENU_CUSTOM_TITLE_KEY_NAME]) || null;
    return (customTitleDict && customTitleDict[this.$i18n.locale]) || this.$t(menuItem.title) as string;
  }

}
