









import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter.vue';
import MenuHintsGuide from '@/_modules/promo/components/menu-hints-guide/menu-hints-guide.vue';
import SeoHelper from '@/_helpers/seo-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service';

const ONLINE_CONTACTS_POLLING_INTERVAL = 3000;

@Component({
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
    ContactInfoInviter,
    MenuHintsGuide
  },
})
export default class Promo extends Vue {

  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('eventStore/showPromoCheckAccessPopup') readonly showPromoCheckAccessPopup: boolean; // TODO: use newer _eventStore
  @Getter('promoPageStore/contact') readonly contactInfo: TContact;
  @Getter('eventStore/getEventInfo') readonly eventInfo: TEvent;

  @Action('contactsStore/clearOnlineContactIds') clearOnlineContactIds: () => void;

  public readonly isOBSSettingsDialogVisible: boolean;
  public readonly isZoomSettingsDialogVisible: boolean;
  public readonly isBroadcastTimeCheckDialogVisible: boolean;
  public readonly isEmbedCodeDialogVisible: boolean;

  private destroyed$: Subject<void> = new Subject<void>();
  public isServiceConnected: boolean = false;
  public onlineContactsIntervalId: number = 0;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  @Watch('eventInfo.title', {immediate: true})
  onAppNameChanged(){
    if (!this.eventInfo || (process.env.VUE_APP_NAME !== this.eventInfo.title)) {
      this.manageSeoInfo();
    }
  }

  @Watch('contactInfo', {immediate: true})
  private onContactInfoChange(): void {
    if (this.contactInfo) {
      this.subscribeToContactEvents();
    }
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (this.isServiceConnected) {
      eventDiscoveryService.subscribe('');
      eventDiscoveryService.requestOnlineContacts();
      this.startPollingOnlineContacts();
    } else {
      this.stopPollingOnlineContacts();
      this.clearOnlineContactIds();
    }
  }

  private startPollingOnlineContacts(): void {
    this.onlineContactsIntervalId = window.setInterval(() => {
      eventDiscoveryService.requestOnlineContacts();
    }, ONLINE_CONTACTS_POLLING_INTERVAL)
  }

  private stopPollingOnlineContacts(): void {
    window.clearInterval(this.onlineContactsIntervalId);
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

  // TODO: this block needs refactoring
  public created(): void {
    this.callEventInfo();
    this.callContactInfo();
    this.$store.dispatch('eventStore/callEventTags', this.eventId);
  }

  // TODO: this block needs refactoring
  public mounted(): void {
    this.checkAccess();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stopPollingOnlineContacts();
    this.clearOnlineContactIds();
  }

  private subscribeToContactEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  // TODO: this block needs refactoring
  private async callEventInfo(): Promise<void> {
    await this.$store.dispatch('eventStore/event', this.eventId);
  }

  // TODO: this block needs refactoring
  private async checkAccess(): Promise<void> {
    await this.callEventInfo();
    if (this.eventInfo && this.eventInfo.personal && this.eventInfo.personal.has_access !== true) {
      await this.$store.dispatch('eventStore/showPromoCheckAccessPopup');
      this.$store.commit('promoStore/promoPageError', '');
      await this.$router.push({
        name: 'event-info',
        params: {
          eventId: this.$route.params.eventId
        }
      });
    }
  }

  // TODO: this block needs refactoring — does it still need it?
  private callContactInfo(): void {
    if (this.contactInfo && this.contactInfo.id) {
      return;
    }
    this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId });
  }

  private manageSeoInfo(): void {
    if (this.eventInfo) {
      const eventInfo = this.eventInfo;
      SeoHelper.setEventPageTitle(eventInfo,
        eventInfo.title,
      );
    }
  }

}
