


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import EventHelper from '@/_helpers/event.helper';
import Avatar from '@/_components/avatar/avatar.vue';
import sideBarContactCard from '@/_modules/promo/components/side-bar-contact-card/side-bar-contact-card.vue';
import QrPopup from '@/_modules/promo/components/qr-popup/qr-popup.vue';
import IconAccessCode from '@/_modules/icons/components/sidebar/icon-access-code.vue';
import IconSettings from '@/_modules/icons/components/icon-settings.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import { AxiosResponse } from 'axios';
import SideBarRightUserSettings from '@/_modules/promo/components/side-bar-right-user-settings/side-bar-right-user-settings.vue';
import { TranslateResult } from 'vue-i18n';
import {TPromoPageAttachedContacts} from '@/_types/promo-page/promo-page-attached-contacts.type';

const QR_CODE_URL_DOMAIN_PREFIX = 'https://' + window.location.host;

@Component({
  components: {
    Avatar,
    sideBarContactCard,
    QrPopup,
    IconAccessCode,
    IconSettings,
    IconArrowLeft,
    SideBarRightUserSettings,
  },
})
export default class SideBarRightTabContact extends Vue {

  public isQRCodePopupVisible: boolean = false;
  public qrPopupTitle: TranslateResult = '';
  public qrPopupDescription: TranslateResult = '';
  public qrPopupContents: string = '';
  public exponentAccessCode: string = '';
  public exponentAccessCodeError: boolean = false;

  @Getter('sideBarRightStore/isOpen') readonly isOpen: boolean;
  @Getter('_userStore/user') readonly user: TUser;
  @Getter('_eventStore/event') readonly event: TEvent;
  @Getter('promoPageStore/promoPage') readonly promoPage: TPromoPage;
  @Getter('promoPageStore/contact') readonly myself: TContact;
  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('sideBarRightStore/isUserSettingsOpen') readonly isUserSettingsOpen: boolean;

  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => void;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get urlContactProfile(): string {
    if (!this.myself) {
      return '';
    }

    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'promo-page-contacts-contact',
      params: {
        eventId: this.eventId.toFixed(0),
        contact_id: this.myself.id.toFixed(0)
      }
    }).href;
  }

  public get urlPromoPage(): string {
    if (!this.promoPage) {
      return '';
    }

    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'promo-page-events-company',
      params: {
        eventId: this.eventId.toFixed(0),
        external_id: this.promoPage.external_id
      }
    }).href;
  }

  public get urlEvent(): string {
    return QR_CODE_URL_DOMAIN_PREFIX + this.$router.resolve({
      name: 'event-info',
      params: {
        eventId: this.eventId.toFixed(0),
      }
    }).href;
  }

  public get canEditCompany(): boolean {
    if (!this.promoPage || !this.myself || !this.promoPage.attached_contacts) {
      return false;
    }

    const contact: TPromoPageAttachedContacts = this.promoPage.attached_contacts.find((item) => {
      return item.contact.id === this.myself.id;
    });

    if (!contact) {
      return false;
    }

    return (contact.role === 'owner' || contact.role === 'assistant' || contact.role === 'admin');
  }

  public get isEventOrganizer(): boolean {
    return EventHelper.isContactEventOrganizer(this.event, this.myself);
  }

  public get isEventEditor(): boolean {
    return !this.isEventOrganizer && EventHelper.isContactEventEditor(this.event, this.myself);
  }

  public get contactFullName(): string {
    if (!this.myself) {
      return '';
    }
    let result = [this.myself.name, this.myself.surname].filter(x => x).join(' ');
    if (!result) {
      result = this.$t('contacts.info.noname') as string;
    }
    return result;
  }

  public get contactGeoInfo(): string {
    if (!this.myself) {
      return '';
    }
    return [this.myself.city, this.myself.country].filter(x => x).join(', ');
  }

  public get isOrganizerCabinetCardVisible(): boolean {
    return this.isEventOrganizer || this.isEventEditor;
  }

  public get promoPageGeoInfo(): string {
    if (!this.promoPage) {
      return '';
    }
    const cityName = this.promoPage.city ? this.promoPage.city.name : '';
    const countryName = this.promoPage.country ? this.promoPage.country.name : '';
    return [cityName, countryName].filter(x => x).join(', ');
  }

  public get promoPageDescription(): string {
    if (!this.promoPage || !this.promoPage.description) {
      return '';
    }
    const limit = 43;
    const isTruncated = this.promoPage.description.length > limit;
    return this.promoPage.description.substring(0, limit) + (isTruncated ? '…' : '');
  }

  public get eventGeoInfo(): string {
    if (!this.event) {
      return '';
    }
    const cityName = this.event.city ? this.event.city.name : '';
    const countryName = this.event.country ? this.event.country.name : '';
    return [cityName, countryName].filter(x => x).join(', ');
  }

  @Prop({ type: String, default: '' })
  public readonly tabContentTitle: TranslateResult;

  @Prop({ type: String, default: '' })
  public readonly contactInitials: string;

  @Watch('exponentAccessCode')
  private onExponentAccessCodeChange(): void {
    this.exponentAccessCodeError = false;
  }

  public beforeDestroy(): void {
    this.hideQRPopup();
  }

  private openEditContact(): void {
    if (this.$route.name !== 'promo-page-edit-contact') {
      this.$router.push({name: 'promo-page-edit-contact'});
    }
  }

  private openEditCompany(): void {
    if (this.$route.name !== 'promo-page-edit-company') {
      this.$router.push({name: 'promo-page-edit-company'});
    }
  }

  private openOrganizerCabinet(): void {
    if (this.$route.name !== 'promo-page-cabinet-event-settings') {
      this.$router.push({name: 'promo-page-cabinet-event-settings'});
    }
  }

  public async logout(): Promise<void> {
    await this.$store.dispatch('authStore/authLogout');
    await this.$store.dispatch('_eventStore/reset');
    window.location.href = this.$router.resolve({name: 'event-info', params: {eventId: this.eventId.toFixed(0)}}).href;
    // await this.$router.push({name: 'event-info', params: {eventId: this.eventId.toFixed(0)}});
  }

  private showQRPopup(): void {
    this.isQRCodePopupVisible = true;
    // this.$store.dispatch('sideBarRightStore/setIsQRCodePopupVisible', true);
  }

  public hideQRPopup(): void {
    this.isQRCodePopupVisible = false;
    // this.$store.dispatch('sideBarRightStore/setIsQRCodePopupVisible', false);
  }

  public setQRPopupContents(title: TranslateResult = '', description: TranslateResult = '', strToEncode: string = ''): void {
    if (!strToEncode) {
      return;
    }
    this.qrPopupTitle = title;
    this.qrPopupDescription = description;
    this.qrPopupContents = strToEncode;
  }

  public showQrContact(): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.contact.qrTitle'), this.$t('sideBarTabs.contact.cards.contact.qrDescription'), this.urlContactProfile);
  }

  public showQrCompany(): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.company.qrTitle'), this.$t('sideBarTabs.contact.cards.company.qrDescription'), this.urlPromoPage);
  }

  public showQrEvent(): void {
    this.hideQRPopup();
    this.showQRPopup();
    this.setQRPopupContents(this.$t('sideBarTabs.contact.cards.organizer.qrTitle'), this.$t('sideBarTabs.contact.cards.organizer.qrDescription'), this.urlEvent);
  }

  public async activateExhibitorCode(): Promise<void> {
    this.exponentAccessCodeError = false;
    if (!this.exponentAccessCode) {
      return;
    }
    // TODO: use new store when it is written, refactor this method correspondingly
    const result: AxiosResponse = await this.$store.dispatch('eventStore/activateCode', {
      event_id: this.eventId,
      code: this.exponentAccessCode
    });
    // TODO: remove error handling. Team lead said that for PATCH requests we should just make request and forget about it
    if (!result || !(result.status === 202 || result.status === 200)) {
      this.exponentAccessCodeError = true;
    } else {
      await this.$store.dispatch('promoPageStore/getContactPromoPage', this.eventId);
    }
  }

  public proceedToUserSettings(): void {
    this.$store.dispatch('sideBarRightStore/setIsUserSettingsOpen', true);
  }

  public closeUserSettings(): void {
    this.$store.dispatch('sideBarRightStore/setIsUserSettingsOpen', false);
  }

  // AW-3189
  public showStandaloneAuthPopup(): void {
    this.setStandaloneAuthVisibility(true);
  }

}
