


import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TContact} from '@/_types/contact.type';
import {TUser} from '@/_types/user.type';
import {TEvent} from '@/_types/event.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TMessage} from '@/_types/messages.type';
import IconNoCorrespondence from '@/_modules/icons/components/sidebar/icon-no-correspondence.vue';
import {TranslateResult} from 'vue-i18n';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {TabNames} from '@/_modules/promo/types/side-bar-right-store-state.type';
import EwSearchInput from '@/_modules/standalone-company/components/UI/ew-search-input/ew-search-input.vue';
import IconEventChat from '@/_modules/icons/components/icon-event-chat.vue';
import IconHelp from '@/_modules/icons/components/sidebar/icon-help.vue';
import HelpCrunchService from '@/_services/help-crunch.service';

const CONTACT_SEARCH_LIMIT = 75;
const CONTACT_SEARCH_DEBOUNCE_TIME = 250;
const FILTERER_STYLE_ID_ATTRIBUTE = 'cssSearcherSideBarCorrespondence';

/* This component shows existing correspondences (see getUserCorrespondence)
   and contact search results (see updateFoundContacts).
   Correspondences are more relevant, therefore are shown before found contacts.

   Correspondences are filtered using CSS search.

   searchString is saved into the store because this way it is saved between activeTabName changes.

   Duplicate contacts: contact can be present both in correspondences and in foundContacts,
   so we filter duplicates out from foundContacts in updateFoundContacts();
 */

@Component({
  components: {
    EwAvatar,
    EwSearchInput,
    IconEventChat,
    IconHelp,
    IconNoCorrespondence,
  },
})
export default class SideBarRightTabCorrespondence extends Vue {

  @Getter('_userStore/user') user: TUser;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/promoPage') promoPage: TPromoPage;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('sideBarRightStore/isOpen') isOpen: boolean;
  @Getter('sideBarRightStore/activeTabName') activeTabName: TabNames;
  @Getter('sideBarRightStore/correspondenceTabSearchString') correspondenceTabSearchString: string;
  @Getter('messagesStore/messages') messages: TApiListResponse<TMessage>;

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName: string }) => void;
  @Action('sideBarRightStore/setCorrespondenceTabSearchString') setCorrespondenceTabSearchString: (payload: string) => void;

  public destroyed$: Subject<void> = new Subject<void>();
  public contactsSearch$: Subject<void> = new Subject<void>();
  public isSearchingContacts: boolean = false;
  public foundContacts: TContact[] = [];
  public isSearchedThroughAllContacts: boolean = false;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get searchString(): string {
    return this.correspondenceTabSearchString;
  }

  public set searchString(value: string) {
    this.setCorrespondenceTabSearchString(value);
  }

  public get isResultsAreaVisible(): boolean {
    const isCorrespondenceNotEmpty = !!(this.messages && this.messages.List && this.messages.List.length);
    const isContactSearchNotEmpty = !!(this.foundContacts && this.foundContacts.length);

    return isCorrespondenceNotEmpty || isContactSearchNotEmpty;
  }

  public get isNoContentMessageVisible(): boolean {
    const isCorrespondenceEmpty = this.messages && this.messages.List && this.messages.List.length === 0;
    const isContactSearchEmpty = this.foundContacts && this.foundContacts.length === 0;

    return isCorrespondenceEmpty && isContactSearchEmpty;
  }

  public get isPseudoItemsListVisible(): boolean {
    return !this.searchString;
  }

  @Prop({ type: String, default: '' })
  public readonly tabContentTitle: TranslateResult;

  @Watch('searchString', { immediate: true })
  public onSearchStringChange(): void {
    this.contactsSearch$.next();
  }

  @Watch('activeTabName', {immediate: true})
  public onActiveTabNameSearch(newVal: TabNames, oldVal: TabNames): void {
    if (oldVal !== TabNames.CORRESPONDENCE && newVal === TabNames.CORRESPONDENCE) {
      this.$nextTick(() => {
        this.updateFoundContacts();
      });
    }
  }

  public created(): void {
    this.getUserCorrespondence();
    this.initContactSearch();
  }

  public beforeDestroy(): void {
    this.destroyed$.complete();
  }

  public initContactSearch(): void {
    this.contactsSearch$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(CONTACT_SEARCH_DEBOUNCE_TIME),
    ).subscribe(() => {
      this.updateFoundContacts();
    });
  }

  public async getUserCorrespondence(): Promise<void> {
    if (!this.user) {
      setTimeout(() => {
        this.getUserCorrespondence();
      }, 1000);
      return;
    }

    await this.$store.dispatch('messagesStore/callMessageList', {
      eventId: this.eventId,
      userId: this.user.id,
      limit: 1000
    });
  }

  public async updateFoundContacts(): Promise<void> {
    this.isSearchingContacts = true;
    const searchString = this.searchString.trim();

    if (!searchString) {
      this.foundContacts = [];
      this.filterCorrespondence();
      this.isSearchingContacts = false;
      return;
    }

    const contactsSearchResults: TApiListResponse<TContact> = await this.$store.dispatch('contactsStore/searchContacts', {
      eventId: this.eventId,
      limit: CONTACT_SEARCH_LIMIT,
      offset: 0,
      search: searchString || undefined,
    });

    this.foundContacts = ((contactsSearchResults && contactsSearchResults.List) || []).filter(contact => {
      if (!this.messages || !this.messages.List) {
        return true;
      }
      return !(this.messages.List.find(message => message.contact.id === contact.id));
    });
    this.filterCorrespondence();
    this.isSearchingContacts = false;
  }

  public getDataCSSSearchAttributeValue(message: TMessage): string {
    if (!message || !message.contact) {
      return '';
    }

    const data: string[] = [message.contact.name, message.contact.surname].filter(x => x).map((x: string) => x.toLowerCase());

    return data.join('');
  }

  public filterCorrespondence(): void {
    const val = this.searchString.toLowerCase().trim();
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    let cssSearcher: HTMLStyleElement | null = document.getElementById(FILTERER_STYLE_ID_ATTRIBUTE) as HTMLStyleElement;
    if (!cssSearcher) {
      cssSearcher = document.createElement('style');
      cssSearcher.setAttribute('type', 'text/css');
      cssSearcher.id = FILTERER_STYLE_ID_ATTRIBUTE;
      cssSearcher.setAttribute('rel', 'stylesheet');
      head.appendChild(cssSearcher);
    }
    if (val === '') {
      cssSearcher.innerHTML = '';
    } else {
      cssSearcher.innerHTML = '.tab-content-correspondence .messages-list .message-preview-corresp { display: none !important; }';
      cssSearcher.innerHTML += '.tab-content-correspondence .messages-list .message-preview-corresp[data-csssearch*="' + val + '"] { display: flex !important; }';
    }
  }

  private viewProfileByMessage(message: TMessage): void {
    this.viewProfileByContact(message.contact);
    this.markCorrespondenceRead(message);
  }

  public viewProfileByContact(contact: TContact): void {
    this.openContactCard({
      contactId: contact.id,
      startupTabName: 'messages',
    });
  }

  public markCorrespondenceRead(message: TMessage): void {
    this.$store.dispatch('notificationsStore/setNoticedMessages', [message.id]);
    // this.$store.dispatch('contactsStore/clearContactUnreadMessages', this.myself.id);
  }

  public getFullNameByContact(contact: TContact): TranslateResult | string {
    const fullNameJoined: string = [contact.name, contact.surname].filter(x => x).join(' ');
    return (contact && contact.fullName) || fullNameJoined || this.$t('contacts.info.noname');
  }

  public getMessageDateFormatted(messageDate: string): string {
    let formatString: DateTimeFormat;
    switch (this.$i18n.locale) {
      case 'uk':
      case 'ru':
      case 'bg':
        formatString = DateTimeFormat.CORRESPONDENCE_SNIPPET_DATE_UK;
        break;
      case 'en':
        formatString = DateTimeFormat.CORRESPONDENCE_SNIPPET_DATE_EN;
        break;
      default:
        formatString = DateTimeFormat.CORRESPONDENCE_SNIPPET_DATE_DEFAULT_FORMAT;
    }
    return this.$moment(messageDate).format(formatString);
  }

  public openEventChat(): void {
    try {
      (document.querySelector('.event-chat-button') as HTMLElement).click();
    } catch (e) {
      /* ignore */
    }
  }

  public openSupportChat(): void {
    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) {
      window.setTimeout(() => { this.openSupportChat(); }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

}
