import { render, staticRenderFns } from "./side-bar-right-tab-contact.html?vue&type=template&id=c9ae7a16&scoped=true&"
import script from "./side-bar-right-tab-contact.vue?vue&type=script&lang=ts&"
export * from "./side-bar-right-tab-contact.vue?vue&type=script&lang=ts&"
import style0 from "./side-bar-right-tab-contact.scss?vue&type=style&index=0&id=c9ae7a16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9ae7a16",
  null
  
)

export default component.exports