


import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import { Watch, Vue } from 'vue-property-decorator';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import { TContactTag } from '@/_types/contact-tag.type';
import ValidationHelper from '@/_helpers/validation.helper';
import { TranslateResult } from 'vue-i18n';

type TFormFields = {
  name: string;
  surname: string;
  company_name: string;
  company_position: string;
  email: string;
  phone: string;
}

type TFormFieldsRequired = {
  name: string;
  surname: string;
  company_name: string;
  company_position: string;
  email: string;
}

type TPayloadFields = {
  name?: string;
  surname?: string;
  company_name?: string;
  company_position?: string;
  email?: string;
  event_id?: number;
  tags: TContactTag[];
  phone?: string;
  lang?: string;
}

type TFieldTexts = {
  [key: string]: TranslateResult;
}

@Component({
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      myself: 'promoPageStore/contact',
      event: '_eventStore/event',
      isAuthPopupVisible: 'authStore/isAuthPopupVisible',
    }),
  },
})
export default class ContactInfoInviter extends Vue {

  public formFieldsRequired: TFormFieldsRequired = { // List of fields that are required (by business, not by backend)
    name: '',
    surname: '',
    company_name: '',
    company_position: '',
    email: '',
  };

  public formFields: TFormFields = { // These keys will be sent to backend
    name: '',
    surname: '',
    company_name: '',
    company_position: '',
    email: '',
    phone: '',
  };

  public formFieldsLabelTranslationKeys: TFieldTexts = { // We keep translation keys in camel case, so this map of field_name → translationKeyName is needed
    name: 'name',
    surname: 'lastName',
    company_name: 'companyName',
    company_position: 'jobPosition',
    email: 'email',
    phone: 'phone',
  };

  public formFieldsErrors: TFieldTexts = { // Error texts are reactive. Displayed if they are truey.
    name: '',
    lastName: '',
    jobPosition: '',
    email: '',
    phone: '',
  };

  public isSavingInProgress: boolean = false;
  public isSubmitButtonEnabled: boolean = false;

  public readonly isAuthenticated: boolean;
  public readonly myself: TContact;
  public readonly event: TEvent;
  public readonly isAuthPopupVisible: boolean;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isAllFilledRequired(): boolean {
    this.prefillFormFields();
    for (const i in this.formFieldsRequired) {
      if (Object.prototype.hasOwnProperty.call(this.formFieldsRequired, i) && (!this.myself || !this.myself[i as keyof TFormFieldsRequired])) {
        return false;
      }
    }
    return true;
  }

  public get isPageEditForm(): boolean {
    return this.$route.name === 'promo-page-edit-contact';
  }

  public get isPopupShown(): boolean {
    if (this.event) {
      return !this.isAllFilledRequired && !this.isPageEditForm && !this.isAuthPopupVisible;
    }
    return false;
  }

  public get eventLanguages(): string[] {
    if (!this.event || !this.event.languages || !this.event.languages.length) {
      return ['en'];
    }
    return this.event.languages;
  }

  @Watch('myself', { deep: true })
  private onMyselfChange(): void {
    this.clearFormFields();
    this.prefillFormFields();
  }

  @Watch('formFields', {immediate: true, deep: true})
  private onFormFieldsChange(): void {
    let isFormFieldsRequiredFilled = true;
    for (const i in this.formFieldsRequired) {
      if (Object.prototype.hasOwnProperty.call(this.formFieldsRequired, i) && (!this.formFields || !this.formFields[i as keyof TFormFieldsRequired])) {
        isFormFieldsRequiredFilled = false;
      }
    }
    this.isSubmitButtonEnabled = isFormFieldsRequiredFilled && ValidationHelper.isValidEmail(this.formFields.email);
  }

  public created(): void {
    this.clearFormFields();
  }

  private proceedToEditForm(): void {
    this.$router.push({
      name: 'promo-page-edit'
    });
  }

  private clearPreviousErrors(): void {
    for (const i in this.formFieldsErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formFieldsErrors, i)) {
        this.formFieldsErrors[i] = '';
      }
    }
  }

  /* If the contact has some data already, show it in the popup */
  private prefillFormFields(): void {
    if (!this.myself) {
      return;
    }
    for (const i in this.formFields) {
      if (Object.prototype.hasOwnProperty.call(this.formFields, i)) {
        this.formFields[i as keyof TFormFields] = this.myself[i as keyof TFormFields] || this.formFields[i as keyof TFormFields];
      }
    }
  }

  private clearFormFields(): void {
    this.formFields = {
      name: '',
      surname: '',
      company_name: '',
      company_position: '',
      email: '',
      phone: '',
    };
  }

  /* Removes the error state from a field if it is not empty */
  private clearErrorIfNotEmpty(key: string): void {
    if (this.formFields[key as keyof TFormFields] !== '') {
      this.formFieldsErrors[(this.formFieldsLabelTranslationKeys[key] as string)] = '';
    }
  }

  private async submitHandler(): Promise<void> {

    let errorCount = 0;

    this.clearPreviousErrors();

    if (!this.formFields.name) {
      errorCount++;
      this.formFieldsErrors.name = this.$t('contacts.popups.contactInfoInviter.fieldErrors.name');
    }

    if (!this.formFields.surname) {
      errorCount++;
      this.formFieldsErrors.lastName = this.$t('contacts.popups.contactInfoInviter.fieldErrors.lastName');
    }

    if (!this.formFields.company_name) {
      errorCount++;
      this.formFieldsErrors.companyName = this.$t('contacts.popups.contactInfoInviter.fieldErrors.companyName');
    }

    if (!this.formFields.company_position) {
      errorCount++;
      this.formFieldsErrors.jobPosition = this.$t('contacts.popups.contactInfoInviter.fieldErrors.jobPosition');
    }

    if (ValidationHelper.isValidEmail(this.formFields.email) === false) {
      errorCount++;
      this.formFieldsErrors.email = this.$t('errors.validation.email_invalid');
    }

    if (!this.formFields.email) {
      errorCount++;
      this.formFieldsErrors.email = this.$t('contacts.popups.contactInfoInviter.fieldErrors.email');
    }

    if (this.formFields.phone && this.formFields.phone.length < 5) {
      errorCount++;
      this.formFieldsErrors.phone = this.$t('contacts.popups.contactInfoInviter.fieldErrors.phone');
    }

    if (errorCount === 0) {

      let payloadCount = 0;

      const payload: TPayloadFields = {
        tags: this.myself.tags ? this.myself.tags : [],
      };
      for (const i in this.formFields) {
        if (Object.prototype.hasOwnProperty.call(this.formFields, i) && this.formFields[i as keyof TFormFields] !== '') {
          payloadCount++;
          payload[i as keyof TFormFields] = this.formFields[i as keyof TFormFields];
        }
      }

      if (!payloadCount) {
        return;
      }

      payload.event_id = this.eventId;

      this.startProgressIndication();
      const success = await Promise.all( this.eventLanguages.map(async (langCode) => {
        payload.lang = langCode;
        await this.$store.dispatch('promoPageStore/editContact', payload);
      }));

      if (success) {
        // line commented at A. Lyskov request, ask Matvey await this.$store.dispatch('_userStore/menuUserGuideSwitcher', true);
        this.endProgressIndication();
      }
    }

  }

  private onFieldKeyup(fieldKey: string): void {

    this.clearErrorIfNotEmpty(fieldKey);

    if (fieldKey === 'phone') {
      this.cleanUpPhoneNumber();
    }
  }

  private cleanUpPhoneNumber(): void {
    this.formFields.phone = '+' + this.formFields.phone.replace(/[^\d]/g, '');
    if (this.formFields.phone === '+') {
      this.formFields.phone = '';
    }
  }

  private startProgressIndication(): void {
    this.isSavingInProgress = true;
  }

  private endProgressIndication(): void {
    this.isSavingInProgress = false;
  }

  /* If some info has been entered,
     * save it before route change
     */
  private async onAddMoreInfoClick(): Promise<void> {

    let payloadCount = 0;

    const payload: TPayloadFields = {
      tags: this.myself.tags ? this.myself.tags : [],
    };
    for (const i in this.formFields) {
      if (Object.prototype.hasOwnProperty.call(this.formFields, i) && this.formFields[i as keyof TFormFields] !== '') {
        payloadCount++;
        payload[i as keyof TFormFields] = this.formFields[i as keyof TFormFields];
      }
    }

    if (!payloadCount || !payload.name) {
      this.proceedToEditForm();
      return;
    }

    payload.event_id = this.eventId;

    try {
      await this.$store.dispatch('promoPageStore/editContact', payload);
    } finally {
      this.proceedToEditForm();
    }
  }

}
