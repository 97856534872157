


import Component, { mixins } from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TabNames } from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import Avatar from '@/_components/avatar/avatar.vue';
import sideBarRightTabContact
  from '@/_modules/promo/components/side-bar-right-tab-contact/side-bar-right-tab-contact.vue';
import sideBarRightTabCorrespondence
  from '@/_modules/promo/components/side-bar-right-tab-correspondence/side-bar-right-tab-correspondence.vue';
import EwClosePopUpIcon from '@/_modules/icons/components/ew-close-pop-up-icon.vue';
import IconCorrespondence from '@/_modules/icons/components/sidebar/icon-correspondence.vue';
import IconSchedule from '@/_modules/icons/components/sidebar/icon-schedule.vue';
import IconNotifications from '@/_modules/icons/components/sidebar/icon-notifications.vue';
import SideBarRightTabNotifications
  from '@/_modules/promo/components/side-bar-right-tab-notifications/side-bar-right-tab-notifications.vue';
import SideBarRightTabSchedule
  from '@/_modules/promo/components/side-bar-right-tab-schedule/side-bar-right-tab-schedule.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin.ts';
import LastContacts from '@/_modules/promo/components/side-bar-right/last-contacts/last-contacts.vue';

import { TEventRestrictions } from '@/_modules/events/api/event/event.api';
import { TContactPermissions } from '@/_modules/contacts/store/contacts.store';

@Component({
  components: {
    Avatar,
    sideBarRightTabContact,
    sideBarRightTabCorrespondence,
    EwClosePopUpIcon,
    IconCorrespondence,
    IconSchedule,
    IconNotifications,
    SideBarRightTabNotifications,
    SideBarRightTabSchedule,
    LastContacts
  },
})
export default class PromoSideBarRight extends mixins(NotificationsMixin) {

  @Getter('_userStore/user') readonly userInfo: TUser;
  @Getter('_eventStore/event') readonly event: TEvent;
  @Getter('promoPageStore/promoPage') readonly promoPage: TPromoPage;
  @Getter('promoPageStore/contact') readonly contactInfo: TContact;
  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('notificationsStore/messagesCount') readonly messagesCount: number;
  @Getter('notificationsStore/meetingsCount') readonly meetingsCount: number;
  @Getter('notificationsStore/noticedMeetingsCount') readonly noticedMeetingsCount: number;
  @Getter('notificationsStore/noticedMessagesCount') readonly noticedMessagesCount: number;
  @Getter('sideBarRightStore/isOpen') readonly isOpen: boolean;
  @Getter('sideBarRightStore/activeTabName') readonly activeTabName: TabNames;
  @Getter('sideBarRightStore/isUserSettingsOpen') readonly isUserSettingsOpen: boolean;
  @Getter('_eventStore/eventRestrictions') eventRestrictions: TEventRestrictions;
  @Getter('contactsStore/contactPermissions') contactPermissions: TContactPermissions;

  @Action('sideBarRightStore/setComponentPresence') setComponentPresence: (value: boolean) => void;
  @Action('sideBarRightStore/open') openTheSideBar: () => void;
  @Action('sideBarRightStore/close') closeTheSideBar: () => void;
  @Action('sideBarRightStore/toggle') toggleTheSideBar: () => void;
  @Action('sideBarRightStore/clearActiveTab') clearActiveTab: () => void;
  @Action('sideBarRightStore/setIsUserSettingsOpen') setIsUserSettingsOpen: (isOpen: boolean) => void;
  @Action('sideBarRightStore/setActiveTab') setActiveTab: (activeTabName: TabNames) => void;

  public TabNames: typeof TabNames = TabNames;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get contactInitials(): string {
    const defaultInitialsText = '';
    if (!this.contactInfo) {
      return defaultInitialsText;
    } else if (!this.contactInfo.name || !this.contactInfo.surname) {
      return defaultInitialsText;
    }
    return this.contactInfo.name.substring(0, 1).toUpperCase() + this.contactInfo.surname.substring(0, 1).toUpperCase();
  }

  public created(): void {
    this.setComponentPresence(true);
  }

  public mounted(): void {
    this.setComponentPresence(true);
  }

  public beforeDestroy(): void {
    this.setComponentPresence(false);
  }

  private foldTheSideBar(): void {
    this.closeTheSideBar();
    this.clearActiveTab();
  }

  private onMenuItemClick(tabName: TabNames): void {

    if (!this.isOpen) {
      this.openTheSideBar();
    } else if ((tabName === TabNames.CONTACT) && this.isUserSettingsOpen) {
      this.setIsUserSettingsOpen(false);
    } else if (tabName === this.activeTabName) {
      this.toggleTheSideBar();
      tabName = TabNames.NONE;
    }
    this.setActiveTab(tabName);
  }

  public hasRestrictions(restrictionName: string): boolean {
    if (this.eventRestrictions && this.eventRestrictions.restrictions_data) {
      if (this.eventRestrictions.restrictions_data.indexOf(restrictionName) >= 0) {
        if (this.eventRestrictions.restrictions_data[this.eventRestrictions.restrictions_data.indexOf(restrictionName)] === restrictionName) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  public hasPermissions(permissionName: string): boolean {
    if (this.contactPermissions && this.contactPermissions.permissions_data) {
      if (this.contactPermissions.permissions_data.indexOf(permissionName) >= 0) {
        if (this.contactPermissions.permissions_data[this.contactPermissions.permissions_data.indexOf(permissionName)] === permissionName) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

}
